import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './logoutbutton.css'


function LogoutBtn() {
    const navigate = useNavigate();

    const handleLogout = async (e) => {
        const response = await fetch('/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify()
        });
        const result = await response.json();
        if(result.status) {
            navigate('/')
        }
    }

    return (
        <div>
            <button onClick={(e) => handleLogout(e.target.value)}>로그아웃</button>
        </div>
    );
}

export default LogoutBtn;