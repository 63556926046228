import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Ticketing from './pages/ticketing';
import TicketView from './pages/viewticket';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ticketing" element={<Ticketing />} />
        <Route path="/viewticket" element={<TicketView/>}/>
        <Route path="*" element={<Home />} /> {/* For unknown routes */}
      </Routes>
    </Router>
  );
}

export default App;
