import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutBtn from './logoutbutton';

import './ticketing.css'

function Ticketing() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [register, setRegister] = useState(false);
    const [selected, setSelected] = useState(null);
    const [userInfo, setUserInfo] = useState({
        name: '',
        phone: ''
    });
    const [layout, setLayout] = useState([[]]);
    const [version, setVersion] = useState(0); // Version to force re-render
    const canvasRef = useRef(null);
    const boxSize = 50;
    const padding = 10;

    const loadLayout = async (userInfo) => {
        try {
            const response = await fetch('/api/layout', {
                method: 'GET',
                credentials: 'include',
            });
            const result = await response.json();

            setLayout(result.payload);
            setUserInfo(userInfo);
            setVersion(v => v + 1); // Increment version to trigger re-render
            setLoading(false);
        } catch (error) {
            console.error('Error loading layout:', error);
            navigate('/');
        }
    };

    useEffect(() => {
        const checkSessionAndLoadLayout = async () => {
            try {
                // Check session
                const sessionResponse = await fetch('/api/session', {
                    method: 'POST',
                    credentials: 'include',
                });
                const sessionResult = await sessionResponse.json();

                if (!sessionResponse.ok || !sessionResult.status) {
                    navigate('/'); // Redirect to login if session is invalid
                } else if ('seat_id' in sessionResult.user_info) {
                    navigate('/viewticket');
                }

                // Load layout data
                await loadLayout(sessionResult.user_info);
            } catch (error) {
                console.error('Error checking session or loading layout:', error);
                navigate('/');
            }
        };

        const intervalId = setInterval(checkSessionAndLoadLayout, 1000);
        return () => clearInterval(intervalId);
    }, [navigate]);

    // Clear selected seat if it’s no longer valid (now occupied)
    useEffect(() => {
        if (selected && layout.flat().every(seat => seat[0] !== selected || seat[1])) {
            setSelected(null); // Clear if the seat is occupied
        }
    }, [layout, selected]);

    useEffect(() => {
        if (!loading) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            const stageHeight = boxSize;
            const stagePadding = 20;

            const drawLayout = () => {
                context.clearRect(0, 0, canvas.width, canvas.height);

                // Draw "Stage" box at the top
                context.strokeStyle = '#000';
                context.lineWidth = 2;
                context.strokeRect(0, 0, canvas.width, stageHeight);

                context.fillStyle = '#000';
                context.font = `${boxSize / 2}px Arial`;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText('스테이지', canvas.width / 2, stageHeight / 2);

                // Draw seating layout below the "Stage" box with added padding
                layout.forEach((row, rowIndex) => {
                    row.forEach((cell, colIndex) => {
                        const [seatId, occupied] = cell;
                        const x = colIndex * (boxSize + padding);
                        const y = rowIndex * (boxSize + padding) + stageHeight + stagePadding;

                        if (seatId !== 'x' && seatId !== 'a') {
                            context.fillStyle = occupied
                                ? '#ff5252' // Occupied seat color
                                : seatId === selected
                                    ? '#42a5f5' // Selected seat color
                                    : '#cccccc'; // Default seat color
                            context.fillRect(x, y, boxSize, boxSize);

                            // Add seat ID text at the center of the seat square
                            context.fillStyle = '#000';
                            context.font = `${boxSize / 4}px Arial`;
                            context.textAlign = 'center';
                            context.textBaseline = 'middle';
                            context.fillText(seatId, x + boxSize / 2, y + boxSize / 2);
                        } else if (seatId === 'a') {
                            // Draw alley with only borders on the left and right
                            context.strokeStyle = '#e0e0e0';
                            context.lineWidth = 2;

                            // Draw left border
                            context.beginPath();
                            context.moveTo(x, y);
                            context.lineTo(x, y + boxSize);
                            context.stroke();

                            // Draw right border
                            context.beginPath();
                            context.moveTo(x + boxSize, y);
                            context.lineTo(x + boxSize, y + boxSize);
                            context.stroke();
                        }
                    });
                });
            };

            const handleMouseMove = (event) => {
                const { offsetX, offsetY } = event;
                const col = Math.floor(offsetX / (boxSize + padding));
                const adjustedOffsetY = offsetY - stageHeight - stagePadding;
                const row = Math.floor(adjustedOffsetY / (boxSize + padding));

                drawLayout();

                if (row >= 0 && layout[row] && layout[row][col]) {
                    const [seatId, occupied] = layout[row][col];
                    if (seatId !== 'x' && seatId !== 'a' && !occupied) {
                        const x = col * (boxSize + padding);
                        const y = row * (boxSize + padding) + stageHeight + stagePadding;
                        context.fillStyle = '#ffa726'; // Hover color for unoccupied seats
                        context.fillRect(x, y, boxSize, boxSize);

                        // Redraw seat ID text to keep it visible on hover
                        context.fillStyle = '#000';
                        context.font = `${boxSize / 4}px Arial`;
                        context.textAlign = 'center';
                        context.textBaseline = 'middle';
                        context.fillText(seatId, x + boxSize / 2, y + boxSize / 2);
                    }
                }
            };

            const handleClick = (event) => {
                const { offsetX, offsetY } = event;
                const col = Math.floor(offsetX / (boxSize + padding));
                const adjustedOffsetY = offsetY - stageHeight - stagePadding;
                const row = Math.floor(adjustedOffsetY / (boxSize + padding));

                if (row >= 0 && layout[row] && layout[row][col]) {
                    const [seatId, occupied] = layout[row][col];
                    if (seatId !== 'x' && seatId !== 'a' && !occupied) {
                        setSelected(seatId);
                        setRegister(true);
                        drawLayout();
                    }
                }
            };

            canvas.width = layout[0].length * (boxSize + padding);
            canvas.height = layout.length * (boxSize + padding);

            drawLayout();
            canvas.addEventListener('mousemove', handleMouseMove);
            canvas.addEventListener('click', handleClick);

            return () => {
                canvas.removeEventListener('mousemove', handleMouseMove);
                canvas.removeEventListener('click', handleClick);
            };
        }
    }, [loading, layout, selected, version]);


    const handleRegister = async () => {
        if (selected == null) {
            alert("자리를 선택해주세요");
            return;
        }

        const req = { 'seat_id': selected };

        const resp = await fetch('/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req)
        });
        const result = await resp.json();

        if (result.success) {
            navigate('/viewticket');
        } else {
            setSelected(null);
            setRegister(false);
            alert(result.reason);
        }
    };

    if (loading) {
        return (
            <div id="ticketing_container" style={{minHeight: "100vh"}}>
                Loading...
            </div>
        );
    } else {
        return (
            <div id="ticketing_container">
                <div style={{marginBottom: "10px"}}>안녕하세요, {userInfo.name} 님 </div>
                <button
                    className="register"
                    disabled={!register}
                    onClick={handleRegister}
                >
                    {selected ? `${selected} 예매하기` : "좌석을 선택하세요"}
                </button>

                <div id="canvas_container">
                    <canvas key={version} ref={canvasRef} style={{ cursor: 'pointer', border: '1px solid #ccc' }} />
                </div>
                <LogoutBtn />
            </div>
        );
    }
}

export default Ticketing;
