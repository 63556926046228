import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';

import LoginForm from "./login";

function Home() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch('/api/session', {
                    method: 'POST',
                    credentials: 'include',
                });
                const result = await response.json();

                if (response.ok && result.status) {
                    navigate('/ticketing');
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error checking session:', error);
                navigate('/'); // Redirect to login on error
            }
        };

        checkSession();
    }, [navigate]);

    if (loading) {
        return (
            <div>
                Loading...
            </div>
        );
    } else {
        return (
            <div id="home">
                <div id="header">
                    <div id="blended_text">블랜디드 온 스테이지</div>
                    <div id="ticketing_text">티켓팅</div>
                </div>
                <LoginForm></LoginForm>
            </div>
        );
    }
}

export default Home;
