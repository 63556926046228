import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css'

function LoginForm() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phonel, setPhonel] = useState('');
    const [phoner, setPhoner] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        let phone = "010" + phonel + phoner;
        if (!/^\d+$/.test(phone)) {
            alert("전화번호는 숫자로만 이루어져야 합니다.");
            return;
        }
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, phone }),
        });

        const result = await response.json();
        if (result.status) {
            navigate('/ticketing');
        }
    };

    return (
        <div id="loginform">
            <form onSubmit={handleSubmit}>
                <div id="loginformgrid">
                    <label htmlFor="name" style={{ gridArea: 'label1' }}><b>이름</b></label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        style={{ gridArea: 'input1' }}
                    />
                    <label htmlFor="phone" style={{ gridArea: 'label2' }}>
                        <b>전화번호</b>
                    </label>
                    <div style={{ gridArea: 'input2' }} >
                        010-
                        <input
                            type="tel"
                            pattern="\d*"
                            maxlength="4"
                            minlength="4"
                            id="phonel"
                            value={phonel}
                            onChange={(e) => setPhonel(e.target.value)}
                            required
                        />
                        -
                        <input
                            type="tel"
                            pattern="\d*"
                            maxlength="4"
                            minlength="4"
                            id="phoner"
                            value={phoner}
                            onChange={(e) => setPhoner(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <button type="submit">입장</button>
            </form>
        </div>
    );
}

export default LoginForm;