import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './viewticket.css';
import LogoutBtn from "./logoutbutton";
import CancelBtn from './cancelbutton';


function TicketView() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch('/api/session', {
                    method: 'POST',
                    credentials: 'include',
                });
                const result = await response.json();

                if (!response.ok || !result.status) {
                    navigate('/'); // Session is invalid, redirect to login page
                }
                if (!('seat_id' in result.user_info)) {
                    navigate('/ticketing');
                }
                setUserInfo(result.user_info);
                setLoading(false);
            } catch (error) {
                console.error('Error checking session:', error);
                navigate('/'); // Redirect to login on error
            }
        };
        checkSession();
    }, [navigate]);

    if (loading) {
        return (
            <div id="home" style={{minHeight: "100vh"}}>
                Loading...
            </div>
        )
    } else {
        return (
            <div id="home">
                <div id="header">
                    <div id="blended_text">블랜디드 온 스테이지</div>
                    <div id="ticketing_text">티켓 예매 내역</div>
                </div>
                <div id="user_info">
                    <div>성함: <b>{userInfo.name}</b></div>
                    <div>전화번호: {userInfo.phone}</div>
                    <div>좌석번호: <b>{userInfo.seat_id}</b></div>
                </div>
                <div id="event_info">
                    <div>일시: 2024년 12월 20일 PM 7:30</div>
                    <div>장소: 한양예술극장</div>
                </div>
                <div id="letter">
                    친애하는 <b>{userInfo.name}</b> 님께 <br></br><br></br>

                    안녕하세요 <b>{userInfo.name}</b> 님, <br></br>
                    블랜디드 온 스테이지 티켓팅에 성공하신 것을 진심으로 축하드립니다! <br></br>
                    다양한 전공의 학생들이 한 학기 동안 열정을 쏟아부어 만든 무대. <br></br>
                    신~나게 즐기실 준비되셨나요? <br></br>
                    많은 노력을 기울여 만들어진 만큼, 최대한 많은 분들께 관람기회를 제공하고자 합니다. <br></br>
                    혹여나 참석이 어려우시면, <b>성함</b>과 <b>전화번호</b>를 첨부하여 문자(<a href="tel:+821093360767">+82 010-9336-0767</a>)를 보내주시기 바랍니다. <br></br>
                    <br></br>
                    예매 내역 페이지에 추가적인 문의사항은 <a href="https://www.instagram.com/blended_on_stage?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">@blended_on_stage</a>
                    인스타그램 DM 또는 <a href="mailto:jongmunyang@hanyang.ac.kr">jongmunyang@hanyang.ac.kr</a> 로 연락주시면 감사하겠습니다.
                    <br></br>
                    그럼, 무대에서 뵙겠습니다!
                </div>

                <LogoutBtn></LogoutBtn>
            </div>
        );
    }
}

export default TicketView;